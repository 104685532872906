import React, { useState } from 'react';
import { Link } from 'gatsby';
import '../scss/main.scss';
import * as styles from '../scss/components/contentGrid.module.scss';
import Header from '../components/Header';
import MenuModal from '../components/MenuModal';
import Footer from '../components/Footer';

export default function NotFoundPage() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen ? null : (
        <main className={styles.main}>
          <h2 className={styles.categoryH2}>404</h2>
          <p className={styles.body404}>대박사건!</p>
          <p className={styles.body404}>잘 못 찾으셨네요.</p>
          <Link to="/" className={styles.body404}>홈 페이지로 돌아가시고 다시 찾아보세요.</Link>
        </main>
      )}
      {menuOpen ? null : <Footer />}
      <MenuModal menuOpen={menuOpen} />
    </>
  );
}
